import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Link, Element } from 'react-scroll';
import './App.css';

const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    institution: '',
    phone: '',
    message: ''
  });

  const [isModalVisible, setIsModalVisible] = useState(false); // Modal state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    emailjs.send(
      'service_v3wqtwt',
      'template_1kl3x0u',
      formData,
      '5sTmvO4Sqe8YMi6P6'
    ).then((result) => {
      console.log('Email successfully sent!', result.text);
      alert('Message successfully sent!');
    }).catch((error) => {
      console.log('Failed to send email.', error.text);
      alert('Failed to send message. Please try again later.');
    });
  };

  const handleLaunchClick = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="App">
      <header className="bg-dark-purple py-3 fixed-top">
        <nav className="container d-flex justify-content-between align-items-center">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link text-yellow" to="header" smooth={true} duration={500} offset={-70}>Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-yellow" to="about" smooth={true} duration={500} offset={-70}>About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-yellow" to="contact" smooth={true} duration={500} offset={-70}>Contact</Link>
            </li>
          </ul>
          <button className="btn btn-warning" onClick={handleLaunchClick}>Launch App</button>
        </nav>
      </header>

      <main className="mt-5 pt-5">
        <Element name="header">
          <section className="hero text-center py-5">
            <div className="container">
              <h1>Your Gateway to Tokenized Commodities</h1>
              <p className="lead my-4">
                At Qommodex, we revolutionize the commodity market by tokenizing assets such as oil, gold, and silver. Our platform provides a seamless exchange for buying, selling, and staking these tokens. Leveraging advanced blockchain technology, we aim to lower transaction costs, increase transaction speed, enhance supply chain transparency, and boost liquidity.
              </p>
              <div>
                <button className="btn btn-outline-warning">Learn More</button>
              </div>
            </div>
          </section>
        </Element>

        <Element name="about">
          <div className="text-center">
            <h1>Services we offer</h1>
          </div>
          <section className="features py-5 bg-light">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-3 text-center">
                  <h2 className="h5">Tokenization Service</h2>
                  <p>Easily tokenize your assets and list them on our platform.</p>
                </div>
                <div className="col-md-6 col-lg-3 text-center">
                  <h2 className="h5">Insurance</h2>
                  <p>Protect your digital assets on secured blockchain.</p>
                </div>
                <div className="col-md-6 col-lg-3 text-center">
                  <h2 className="h5">Defi Services</h2>
                  <p>Earn rewards by staking and borrowing tokens.</p>
                </div>
                <div className="col-md-6 col-lg-3 text-center">
                  <h2 className="h5">P2P Services</h2>
                  <p>Trade directly for better rates and increased flexibility.</p>
                </div>
              </div>
            </div>
          </section>
        </Element>

        <Element name="contact">
          <div className="container text-center">
            <h1>Join our Exclusive Early Access Program</h1>
            <p className="lead my-4">
              Tailored for financial institutions, investment banks, hedge funds, and commodity dealers. Benefit from personalized onboarding sessions and training workshops, and discover how tokenization can enhance the liquidity, streamline transactions, and mitigate risks in your commodity trading process.
            </p>
          </div>
          <section className="py-5 gradient-section">
            <div className="form-container">
              <h2 className="h3 text-center">Contact Us</h2>
              <form className="mt-4">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="institution" className="form-label">Institution Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="institution"
                    name="institution"
                    value={formData.institution}
                    onChange={handleChange}
                    placeholder="Enter your institution name"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Phone Number</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button type="button" onClick={handleSubmit} className="btn btn-warning">Submit</button>
              </form>
            </div>
          </section>
        </Element>

        <Element name="footer">
          <footer className="bg-dark-purple py-3">
            <div className="container d-flex justify-content-between text-yellow">
              <p>&copy; 2023 YourCompany</p>
              <nav>
                <ul className="nav">
                  <li className="nav-item">
                    <Link className="nav-link text-yellow" to="header" smooth={true} duration={500} offset={-70}>Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-yellow" to="about" smooth={true} duration={500} offset={-70}>About</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-yellow" to="contact" smooth={true} duration={500} offset={-70}>Contact</Link>
                  </li>
                </ul>
              </nav>
              <div>
                <a href="https://www.instagram.com/qommodex/?igsh=MTdkdnh3cGFwYzdzYQ%3D%3D" className="text-yellow mr-3" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.twitter.com" className="text-yellow mr-3" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.facebook.com" className="text-yellow" target="_blank">
                  <i className="fab fa-facebook"></i>
                </a>
              </div>
            </div>
          </footer>
        </Element>
      </main>

      {isModalVisible && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-btn" onClick={closeModal}>
              &times;
            </button>
            <h2>App is coming soon...</h2>
            <p>Stay tuned for updates. We are working hard to bring you the best experience!</p>
            <button className="btn btn-warning" onClick={closeModal}>Got it!</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
